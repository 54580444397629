import axios from "axios";

export const ApiService = axios.create()


ApiService.interceptors.request.use(request => {
        delete ApiService.defaults.headers.common['Cookie']
        delete ApiService.defaults.headers.common['Authorization']
        ApiService.defaults.headers.common['Accept'] = 'application/json'
        return request;
    }, error => {
        return Promise.reject(error);
    }
);
