import React, {useEffect, useState} from "react";
import {useAuthentication} from "components/Hooks/AuthHook";
import axios from "axios";
import {CMS_URLS} from "components/Api/Config";
import Loader from "components/Loader";
import moment from "moment";
import {CalendarDaysIcon, DocumentIcon} from "@heroicons/react/24/outline";
import {ApiRoutes, ApiService} from "@clavkoo/api-service";
import {useDocument} from "../../../components/Hooks/document-hook";
import {DocumentPreview} from "../../../components/Patient/document-preview";


export default function RecentDocuments() {
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [documentUrl, setDocumentUrl] = useState('')
    let {user} = useAuthentication()
    let {uploaded_by} = useDocument()

    useEffect(() => {
        setLoading(true)

        ApiService.get(ApiRoutes.DOCUMENTS.DOCUMENT_BY_PRACTICE(user().practice[0]?.id))
            .then(res => setDocuments(res?.data.slice(0, 4)))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <DocumentPreview isOpen={isOpen} onClose={() => setIsOpen(false)} url={documentUrl}/>
        <div className="card shadow-2xl p-4 rounded border-2 border-green-500">
            {
                !loading ? documents.length === 0 ? <>There are no recent documents.</> :
                        <ul className="list-group">
                            {
                                documents.map(document => {
                                    return <React.Fragment key={document?.id}>
                                        <li key={document?.id}
                                            onClick={() => {
                                                setDocumentUrl(document?.attachment)
                                                setIsOpen(true)
                                            }}
                                            className="flex justify-between gap-x-6 border-b-2 mb-4 border-green-500 cursor-pointer">
                                            <div className="flex min-w-0 gap-x-4">
                                                <DocumentIcon className="h-8 w-8  text-green-600 "/>
                                                <div className="min-w-0 flex-auto">
                                                    <p className="text-sm font-semibold leading-6 text-gray-900">{document?.name}</p>
                                                    <p className="text-xs leading-6 text-gray-500 -mt-1">Patient: {document?.patient?.first_name} {document?.patient?.last_name}</p>
                                                </div>
                                            </div>
                                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                <p className="text-sm leading-6 text-gray-900">{moment(document?.created).local().format('YYYY-MM-DD')}</p>
                                                <p className="text-xs leading-6 text-gray-500 -mt-1">Uploaded
                                                    by {uploaded_by(document?.uploaded_by)}</p>
                                            </div>
                                        </li>
                                    </React.Fragment>
                                })}
                        </ul> :
                    <Loader/>
            }
        </div>
    </>
}
