const CMS_VERSION = "v1"
const CMS_BASE_URL = process.env.REACT_APP_BASE_API ?? "https://api-qa.clavkoo.org/api"
//const CMS_BASE_URL = process.env.REACT_APP_BASE_API ?? "http://127.0.0.1:8000/api"
export const APP_ID = 'CLAVKOO';

const CMS_URL = `${CMS_BASE_URL}/${CMS_VERSION}/`

export const FORM_URLS = {
    FORM: function (form_id) {
        return `${CMS_URL}forms/${form_id}`
    },
    PATIENT_FORMS: function (patient_id) {
        return `${CMS_URL}forms/records/${patient_id}`
    },
    FORM_TYPES: `${CMS_URL}forms`,
    FORM_COMMUNICATION: `${CMS_URL}forms/send`
}

export const PATIENT_URLS = {
    PATIENTS_LIST: `${CMS_URL}users/patients`,
    NOTES: `${CMS_BASE_URL}/${CMS_VERSION}/notes/`,
    PATIENT_RECORD: function (patient_id, form_type_id) {
        return `${CMS_URL}forms/records/${patient_id}/${form_type_id}`
    }
}

export const CLINICIAN_URLS = {
    PATIENT_LIST: function (clinician_id) {
        return `${CMS_URL}users/patients/practice/${clinician_id}`
    },
    PRACTICE_STATS: function (clinician_id) {
        return `${CMS_URL}users/stats/practice/${clinician_id}`
    },
    NOTES_LIST: function (clinician_id) {
        return `${CMS_URL}notes/practice/${clinician_id}`
    }
}

export const CMS_URLS = {
    AUTHENTICATION: {
        LOGIN: `${CMS_URL}/users/obtain_token`,
        USER_OTP: `${CMS_URL}/users/otp`,
        REGISTER: `${CMS_URL}/users/patient/register`,
    },
    LOGIN: `${CMS_BASE_URL}/${CMS_VERSION}/users/obtain_token`,
    PRACTICES: {
        LIST: `${CMS_URL}/practices/all`,
        CLINICIANS: `${CMS_BASE_URL}/users/clinicians`
    },
    APPOINTMENTS: function (user_id) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/appointments/${user_id}`
    },
    USER_APPOINTMENTS: function (user_id) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/meetings/patient/${user_id}`
    },
    USER_DETAILS: function (user_id) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/users/details/${user_id}`
    },
    REFRESH_TOKEN: function (token) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/users/refresh_token/${token}`
    },
    USER_OTP: `${CMS_BASE_URL}/${CMS_VERSION}/users/otp`,
    REFER: `${CMS_BASE_URL}/${CMS_VERSION}/users/refer`,
    TYPES: `${CMS_BASE_URL}/${CMS_VERSION}/users/types`,
    REFERRING_CLINICIAN: `${CMS_BASE_URL}/${CMS_VERSION}/network/clinicians`,
    DOCTOR_REGISTER: `${CMS_URL}users/doctor/register`,
    USERS: {
        DETAILS: function (id_number) {
            return `${CMS_BASE_URL}/${CMS_VERSION}/users/details/${id_number}`
        },
        CLINICIANS: `${CMS_BASE_URL}/${CMS_VERSION}/users/clinicians`,
        MEDICAL_AIDS: `${CMS_URL}/users/medical-aids`
    },
    DOCUMENTS: {
        PATIENT: function (patient_id) {
            return `${CMS_BASE_URL}/${CMS_VERSION}/documents/patient/${patient_id}`

        },
        DOCUMENT: `${CMS_BASE_URL}/${CMS_VERSION}/documents`
    },
    DOWNLOAD_PDF: function (patient_id, form_id) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/forms/records/pdf/${patient_id}/${form_id}`
    },
    INVITE_PATIENT: function (practice_id) {
        return `${CMS_BASE_URL}/${CMS_VERSION}/users/patients/invite/${practice_id}`
    }
}