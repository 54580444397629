import {Cog6ToothIcon} from "@heroicons/react/24/outline";

export default function Loader({full_height = false}) {
    return <div
        className={`self-auto text-center place-content-center mt-5 pt-5 mb-5 pb-5 rowspan-7 ${full_height ? "h-screen" : ""}`}>
        <section
            className={`hero container max-w-screen-lg mx-auto pb-10 text-blue-500 justify-center text-center items-center`}>
            <p className='mt-12'>
                <Cog6ToothIcon className={'h-8 w-auto text-center animate-spin text-green-600 m-auto mt-6 mb-6'}/>
            </p>
        </section>
    </div>
}
