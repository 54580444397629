const CMS_VERSION = "v1"
const CMS_BASE_URL = "https://api-qa.clavkoo.org/api"
const CMS_URL = `${CMS_BASE_URL}/${CMS_VERSION}`
export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${CMS_URL}/users/obtain_token`,
        USER_OTP: `${CMS_URL}/users/otp`,
        REGISTER: `${CMS_URL}/users/patient/register`,
    },
    USERS: {
        MEDICAL_AIDS: `${CMS_URL}/users/medical-aids`,
        DETAIL: function (user_id) {
            return `${CMS_BASE_URL}/${CMS_VERSION}/users/details/${user_id}`
        },
    },
    PRACTICES: {
        LIST: `${CMS_URL}/practices/all`,
        CLINICIANS: `${CMS_BASE_URL}/users/clinicians`,
        PRACTICE_STATS: function (clinician_id) {
            return `${CMS_URL}/users/stats/practice/${clinician_id}`
        }
    },
    CLINICIAN_URLS: {
        PATIENT_LIST: function (clinician_id) {
            return `${CMS_URL}/users/patients/practice/${clinician_id}`
        },
        PRACTICE_STATS: function (clinician_id) {
            return `${CMS_URL}/users/stats/practice/${clinician_id}`
        },
        NOTES_LIST: function (clinician_id) {
            return `${CMS_URL}/notes/practice/${clinician_id}`
        }
    },
    PATIENTS: {
        LIST: `${CMS_URL}/users/patients`,
        NOTES: `${CMS_BASE_URL}/${CMS_VERSION}/notes/`,
        RECORDS: function (patient_id, form_type_id) {
            return `${CMS_URL}forms/records/${patient_id}/${form_type_id}`
        }
    },
    DOCUMENTS: {
        PATIENT: function (patient_id) {
            return `${CMS_URL}/documents/patient/${patient_id}`
        },
        DOCUMENT: `${CMS_URL}/documents`,
        DOCUMENT_BY_PRACTICE: function (practice_id) {
            return `${CMS_URL}/documents/practice/${practice_id}`
        },
        MONTHLY_DOCUMENT_BY_PRACTICE: function (practice_id) {
            return `${CMS_URL}/documents-monthly/practice/${practice_id}`
        },
        DOCUMENT_TYPES: `${CMS_URL}/document-types`
    },
    FORMS: {
        DETAIL: function (form_id) {
            return `${CMS_URL}/forms/${form_id}`
        },
        RECORDS: function (patient_id, form_type_id) {
            return `${CMS_URL}/forms/records/${patient_id}`
        },
        RECORDS_DETAIL: function (patient_id, form_type_id) {
            return `${CMS_URL}/forms/records/${patient_id}/${form_type_id}`
        },
        TYPES: `${CMS_URL}/forms`,
        COMMUNICATION: `${CMS_URL}/forms/send`
    }
}
