import React, {useState, useEffect} from "react";
import {PATIENT_URLS} from "../../Api/Config";
import {useAuthentication} from "../../Hooks/AuthHook";
import {SuccessMsg} from "../../Notification";
import axios from "axios";

import {Fragment} from 'react'
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import Loader from "../../Loader";


export default function AddPatientNote({patient_id}) {
    const [details, setDetails] = useState({})
    const [clinical_note, setClinicalNote] = useState(false)
    const [isLoading, setLoading] = useState(false)
    let {user} = useAuthentication();
    const [notes, setNotes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [limit, setLimit] = useState(3)

    function truncateDisplayNote(text, maxLength) {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    }

    function save_note() {
        setLoading(true)
        fetch(PATIENT_URLS.NOTES, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                patient_id: patient_id,
                clinician_id: user().id_number,
                clinical_note: clinical_note,
                note: details?.note,
                name: details?.name,
            })
        })
            .then(res => res.json())
            .then(() => {
                SuccessMsg('Note Saved!')
                get_notes()
            })
            .finally(() => {
                setLoading(false)
            })
    }


    function get_notes() {
        setLoaded(false)
        axios.get(PATIENT_URLS.NOTES, {
            params: {
                patient_id: patient_id,
                clinician_id: user().id_number
            }
        })
            .then(function (response) {
                setNotes(response.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoaded(true))
    }

    useEffect(get_notes, [patient_id])

    return <>

        <div className="">
            <form className="tw-relative tw-flex-auto" onSubmit={event => {
                event.preventDefault()
                save_note()
            }}>
                <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    type="text"
                    required
                    placeholder="Note name"
                    aria-label=".form-control-sm example"
                    onChange={event => {
                        setDetails((prevState) => ({
                            ...prevState,
                            [`name`]: event.target.value.trim(),
                        }))
                    }}/>
                    <textarea
                        rows={3}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 mt-2 p-2"
                        placeholder="Add your note..."
                        defaultValue={''}
                        required
                        onChange={event => {
                            setDetails((prevState) => ({
                                ...prevState,
                                [`note`]: event.target.value,
                            }))
                        }}
                    />

                <div className="row position-relative">
                    <div className="col-md-4 float-right end-0">
                        <div className="form-group">
                            <button
                                className="flex w-full justify-center mt-2 rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                type="submit">Save Note
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <ul
            role="list"
            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded mt-14"
        >
            {
                !isLoading ? notes.length === 0 ? <div className='p-2'>
                        <p>There are no notes saved for this patient.</p>
                    </div> : notes.slice(0, limit).map((note) => (
                        <li key={note.id}
                            className="relative flex justify-between gap-x-6 px-4 py-2 hover:bg-gray-50 sm:px-6">
                            <div className="flex min-w-0 gap-x-2">
                                <PencilSquareIcon className="h-6 w-6 text-gray-500"/>
                                <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        <div className='text-gray-500'>
                                            <span className="absolute inset-x-0 -top-px bottom-0"/>
                                            <div dangerouslySetInnerHTML={{__html: truncateDisplayNote(note.note, 40)}}></div>
                                        </div>
                                    </p>
                                    <p className="flex text-xs leading-5 text-gray-500">
                                        <p className="relative truncate">
                                            By {note.clinician?.first_name} {note.clinician?.last_name} - {new Date(note?.created).toISOString().slice(0, 10)}
                                        </p>
                                    </p>
                                </div>
                            </div>
                            <div className="flex shrink-0 items-center -mt-4">
                                <TrashIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                            </div>
                        </li>
                    )) :
                    <Loader/>

            }
        </ul>
        <div className="mt-6">
            <button
                className="flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-600 hover:bg-green-600 cursor-pointer"
                onClick={event => {
                    event.preventDefault()
                    setLimit(limit === 3 ? notes.length : 3)
                }}>
                View {limit === 3 ? 'all' : 'top 3' }  ({notes.length})
            </button>
        </div>
    </>
}
