import React from "react";
import {Container} from "reactstrap";
import Footer from "components/Footers/Footer";

const TermsOfUse = () => {
    return <>
        <Container className="mt-6">
            <h1>TERMS OF USE</h1>
            <p>The following Terms of Use for the Clavkoo Platform (“Platform”) is applicable to all Users of the
                Platform. It sets out the terms and conditions pursuant to which You, the User may access the Platform
                and the information therein. By using the Platform, you agree to comply with the relevant contracts,
                policies and procedures that govern your engagement with Clavkoo Platform and explicitly agree to the
                following:</p>
            <br/>
            <h5 className='font-weight-bold'>DEFINITION OF USER: </h5>
            <p>User means those duly licensed healthcare practitioners; and personnel of health care practitioners
                employed by or under contract with health care practitioners and have access to the Platform to assist
                User and to whom Clavkoo Platform has assigned a unique identifier for access to the Platform.</p>
            <h5 className='font-weight-bold'>LIMITED LICENCE:</h5>
            <p>Clavkoo Platform grants to User and User accepts a non-exclusive, non-transferable, limited right to have
                access to and to use the Platform in accordance with your agreement(s) with the Clavkoo Platform. The
                Platform provides access to its systems to support the business objectives of Clavkoo and the Care Team
                and provide a Coordinated Health Care System as outlined in such agreement. Your use of the Platform
                shall comply with all applicable laws, including laws relating to maintenance of privacy, security, and
                confidentiality of patient and other health information and the prohibition to disseminate illegal,
                obscene, threatening, libellous, harassing, or offensive messages, or otherwise unlawful material.</p>
            <h5 className='font-weight-bold'>PERMITTED USES: </h5>
            <p>Except as expressly authorised in the Clavkoo Platform’s policies and procedures, User may access the
                Platform for the purposes of formulating patient records incorporating the patient’s personal
                information and the particulars of the treatment provided to the patient. The User agrees that such
                information is necessary for Clavkoo Platform to render care coordination to the patient. The User
                agrees that they shall not access the Platform or use the Services for any other purposes. In
                particular:<br/>
                <ul>
                    <li>User shall not reproduce, publish, or distribute data in connection with the Platform that
                        infringes any third party’s trademark, copyright, patent, trade secret, publicity, privacy, or
                        other personal or proprietary right;
                    </li>
                    <li>
                        User shall comply with all applicable laws, including laws relating to maintenance of privacy,
                        security, and confidentiality of patient and other health information and the prohibition of the
                        dissemination of unlawful or offensive data.
                    </li>
                    <li>
                        And User shall not:
                        <ul>
                            <li>abuse or misuse the Platform or the Services, including gaining or attempting to gain
                                unauthorised access to the Platform or the data.
                            </li>
                            <li>
                                altering or destroying information in the Platform except in accordance with Clavkoo
                                Platform’s policies and procedures.
                            </li>
                            <li>
                                use the Platform or Services in such a manner that interferes with other users’ use of
                                the Platform.
                            </li>
                            <li>
                                permit the introduction into the Platform of any program, routine or data (such as
                                viruses or worms) that does or may disrupt or in any way impede the operation of the
                                Platform, or alter or destroy any data within it.
                            </li>
                            <li>
                                use the Platform or the Services in a manner that violates Clavkoo Platform’s policies
                                and procedures. User acknowledges that User’s activities on the Platform may be
                                monitored, and are subject to rights of audit and termination of Services/ access to the
                                Platform by Clavkoo Platform team.
                            </li>
                        </ul>
                    </li>
                </ul>
            </p>
            <h5 className='font-weight-bold'>SAFEGUARDS: </h5>
            <p>User shall implement and maintain appropriate safeguards to protect the confidentiality, integrity, and
                availability of the data accessible through the Platform, to protect it against reasonably anticipated
                threats, and to prevent its unauthorised or unlawful use or disclosure. Such safeguards shall comply
                with applicable laws and Clavkoo Platform’s policies and procedures. In particular, the User shall
                maintain appropriate safeguards with regard to all personnel and administrative processes associated
                with the use of the Platform. The user shall establish security incident procedures to immediately
                notify the Platform’s team of any security incident relating to the Platform of which the user becomes
                aware, or any unauthorised use or disclosure of information within or obtained from the Platform, shall
                cooperate with Clavkoo Platform in investigating the incident, and shall take such action to mitigate
                any breach or suspected breach as Clavkoo Platform may direct.</p>
            <h5 className='font-weight-bold'>RESTRICTED USER ACCESS: </h5>
            <p>Clavkoo Platform authorises the User to use their IDs number to access the Platform. Except as required
                by law, User shall not permit any third party to have access to the Platform or to use the Services
                without the prior written agreement of Clavkoo. User shall promptly notify Clavkoo of any order or
                demand for compulsory disclosure of information if the disclosure requires access to or use of the
                Platform, including access by User to respond to the order or demand User shall cooperate fully with
                Clavkoo in connection with any such demand.</p>
            <h5 className='font-weight-bold'>CONFIDENTIALITY: </h5>
            <p>User agrees to hold all Confidential Information in strict confidence and shall take all measures
                necessary to prevent unauthorised copying, use, or disclosure of Confidential Information, and to keep
                the Confidential Information from falling into the public domain or into the possession of persons not
                bound to maintain the confidentiality of Confidential Information. User shall promptly advise Clavkoo in
                writing of any improper disclosure, misappropriation, or misuse of the Confidential Information by any
                person, which may come to the User’s attention.</p>
            <h5 className='font-weight-bold'>THIRD PARTY ACCESS: </h5>
            <p>You consent to authorised third parties of Clavkoo including service providers of Clavkoo; having access
                to information on the Platform for purposes of fulfilment of the Clavkoo Services and maintaining access
                to the Platform. Should the relationship between Clavkoo and any service provider(s) be terminated, You
                agree that the service provider shall be entitled to retain, dispose, destroy, transfer or otherwise
                process information submitted by You in accordance with Platform’s rights and obligations in law and
                instructions to the service provider.</p>
            <h5 className='font-weight-bold'>CHANGE AND TERMINATION: </h5>
            <p>Clavkoo reserves the right to change the Services or the Platform, or to cease operating the Platform or
                any or all of the Services, at any time. Your obligations on termination of your clinical services are
                set out in Your agreement(s) with Clavkoo.</p>
            <h5 className='font-weight-bold'>NO WARRANTY: </h5>
            <p>Access to the Platform and the information contained on the Platform is provided “as is” and “as
                available” without any warranty of any kind, expressed or implied, including but not limited to, the
                implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </p>
            <h5 className='font-weight-bold'>INDEMNIFICATION: </h5>
            <p>User shall be solely responsible for the use of the Platform by User. User agrees to indemnify, defend,
                and hold harmless Clavkoo and its officers, directors, its suppliers and agents from and against any
                claim, cost or liability, including reasonable attorneys’ fees, arising out of:
            </p>
            <ol type='a'>
                <li>the use of the Platform by User,</li>
                <li>any breach by User of any representations, warranties or agreements contained in this Agreement,
                </li>
                <li>the actions of any person gaining access to the Platform under a User ID number,</li>
                <li>anyone using the User ID in a manner that adversely affects the Platform or any information accessed
                    through the Platform, and
                </li>
                <li>the negligent or wilful misconduct of User.</li>
            </ol>
            <h5 className='font-weight-bold'>LIMITATION OF LIABILITY: </h5>
            <p>User is solely responsible for all acts or omissions taken or made in reliance on the Platform or the
                information in the Platform, including inaccurate or incomplete information. It is expressly agreed that
                in no event shall Clavkoo be liable for any special, indirect, consequential, or exemplary damages,
                including but not limited to, loss of profits or revenues, loss of use, or loss of information or data,
                whether a claim for any such liability or damages is premised upon breach of contract, breach of
                warranty, negligence, strict liability, or any other theories of liability, even if Clavkoo has been
                appraised of the possibility or likelihood of such damages occurring. Clavkoo disclaims all liability
                for erroneous transmissions and loss of service
            </p>
            <h5 className='font-weight-bold'>GOVERNING LAW AND JURISDICTION: </h5>
            <p>This Terms of Use incorporating without limitation the privacy policy, as provided by Clavkoo shall be
                governed by the laws of South Africa and the parties submit to the exclusive jurisdiction of the
                relevant South African court(s). Clavkoo reserves the right to amend or make changes to this Terms of
                Use pertaining to the Platform. We will publish the current notice on the Platform specifying the
                version and effective date.
            </p>
        </Container>
        <Footer dark={false}/>
    </>
}
export default TermsOfUse;
