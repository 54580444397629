export const useDocument = () => {

    function uploaded_by(user) {
        console.log(user)
        const {title, first_name, last_name} = user;
        if (title && first_name && last_name) {
            return `${title} ${first_name} ${last_name}`;
        }
        return `${first_name} ${last_name}`;
    }

    return {uploaded_by}
}