import {useAuthentication} from "components/Hooks/AuthHook";
import NewPatients from "pages/clinician/dashboard/patients";
import StatCard from "pages/clinician/dashboard/stat-card";
import {Fragment} from 'react'
import RecentDocuments from "./recent-documents";
import {ApiRoutes} from "@clavkoo/api-service";


export default function ClinicianBoard() {
    let {user} = useAuthentication()

    const clavkoo_stats = [
        {
            title: 'Total Patients',
            background: 'bg-success',
            url: ApiRoutes.PRACTICES.PRACTICE_STATS(user().id_number),
            icon: 'UsersIcon',
            stat_prop: 'patients_all_count',
            path:'/patients'
        }, {
            title: 'This Months Patients',
            background: 'bg-warning',
            url: ApiRoutes.PRACTICES.PRACTICE_STATS(user().id_number),
            icon: 'UserPlusIcon',
            stat_prop: 'patients_month_count',
            path:'/patients'
        },
        {
            title: 'Uploaded Documents',
            background: 'bg-dark',
            url: ApiRoutes.DOCUMENTS.DOCUMENT_BY_PRACTICE(user().practice[0]?.id),
            icon: 'DocumentDuplicateIcon',
            path:'/patients'
        },
        {
            title: 'This Months Documents',
            background: 'bg-dark',
            url: ApiRoutes.DOCUMENTS.MONTHLY_DOCUMENT_BY_PRACTICE(user().practice[0]?.id),
            icon: 'DocumentDuplicateIcon',
            path:'/patients'
        }
    ]

    return <>
        <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">{user().practice[0].name} Document Stats</h3>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                {
                    clavkoo_stats.map(stat => {
                        return <div className='col-md-3'>
                            <StatCard item={stat} title={stat.title} background={stat.background} url={stat.url} icon={stat.icon} stat_prop={stat.stat_prop} path={stat.path}/>
                        </div>
                    })
                }
            </dl>
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2 mt-6">
            <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Recent Documents at {user().practice[0].name}</h3>
                <RecentDocuments />
            </div>
            <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">New Patients at {user().practice[0].name}</h3>
                <NewPatients />
            </div>
        </div>
    </>
}
