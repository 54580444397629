import {Redirect, Route} from "react-router-dom";
import {useAuthentication} from "components/Hooks/AuthHook";

export default function PrivateRoute({component: Component, ...rest}) {
    let {isLogin} = useAuthentication()

    return (
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
                : <Redirect to={{pathname: `/login`}}/>
        )}/>
    );
};
