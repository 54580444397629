import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import PatientProfile from "components/Patient/profile";
import {Breadcrumbs} from "components/Breadcrumbs";
import axios from "axios";
import {CMS_URLS} from "components/Api/Config";
import {useAuthentication} from "components/Hooks/AuthHook";


export default function Profile() {
    const [breadcrumbs, setBreadcrumbs] = useState([])
    let {id} = useParams();
    let {isPatient} = useAuthentication()

    useEffect(()=>{
        axios.get(CMS_URLS.USER_DETAILS(id))
            .then(res => {
                setBreadcrumbs([])
                let tempCrumb = [{
                    'detail': `${res.data.first_name} ${res.data.last_name}`,
                    'link': `/profile/${id}`
                }]
                if (!isPatient()) {
                    tempCrumb.unshift({
                        'detail': 'Patients',
                        'link': `/patients/`
                    })
                }
                setBreadcrumbs(tempCrumb)
            })
    },[])

    return (
        <>
            <Breadcrumbs items={breadcrumbs}/>
            <PatientProfile id={id}/>
        </>
    )
}
