import React, {Fragment, useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import AddPatientNote from "components/Forms/Note/AddPatientNote";
import {FileDrag} from "./fileDrag";
import {PatientDetailsCard} from "../cards/person-card";

import PatientDocumentList from "./patient-document-list";
import {ApiService, ApiRoutes} from "@clavkoo/api-service";
import Loader from "../Loader";


export default function PatientProfile() {
    let {id} = useParams()
    const [profile, setProfile] = useState({})
    const [profileLoaded, setProfileLoaded] = useState(false)

    useEffect(() => {
        setProfileLoaded(false)
        ApiService.get(ApiRoutes.USERS.DETAIL(id))
            .then(res => {
                setProfile(res.data)
            })
            .catch(error => console.error(error))
            .finally(() => setProfileLoaded(true))
    }, [id])

    return <>
        {profileLoaded ? <PatientDetailsCard user={profile}/> : <Loader full_height={true}/>}
        <main className="mt-4 pb-8">
            <div className="mx-auto max-w-full lg:max-w-full">
                <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="profile-overview-title">
                            <div className="overflow-hidden border-2 border-gray-200 rounded shadow-xl">
                                <div className="bg-white p-6">
                                    <div className="sm:flex sm:items-center sm:justify-between">
                                        <div className="sm:flex sm:space-x-5">
                                            <h2 className="text-base font-medium text-gray-600"
                                                id="announcements-title">
                                                Uploaded Documents
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="divide-gray-200 border-t border-gray-200  sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                                    <PatientDocumentList patient_id={id}/>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <section aria-labelledby="announcements-title">
                            <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
                                <div className="p-6">
                                    <h2 className="text-base font-medium text-gray-600" id="announcements-title">
                                        Document Upload
                                    </h2>
                                    <div className="mt-6 flow-root">
                                        <FileDrag patient_id={id}/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section aria-labelledby="recent-hires-title">
                            <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
                                <div className="p-6">
                                    <h2 className="text-base font-medium text-gray-700" id="recent-hires-title">
                                        Notes
                                    </h2>
                                    <div className="mt-6 flow-root">
                                        <AddPatientNote patient_id={id}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    </>
}
