import React from "react";
import PatientsTable from "components/Patient";
import {Breadcrumbs} from "components/Breadcrumbs";


export default function Patients() {
    const breadcrumb = [{'detail': 'Patients', 'link': '/patients'}]
    return <>
        <Breadcrumbs items={breadcrumb}/>
        <PatientsTable/>
    </>
}
