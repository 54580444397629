import {useEffect, useState} from "react";
import {ChevronRightIcon, DocumentIcon, FolderIcon} from "@heroicons/react/24/outline";
import {ApiRoutes, ApiService} from "@clavkoo/api-service";
import {Disclosure} from "@headlessui/react";
import {DocumentPreview} from "./document-preview";
import {useDocument} from "../Hooks/document-hook";
import Loader from "../Loader";

export default function PatientDocumentList({patient_id}) {
    const [types, setTypes] = useState([])
    const [documents, setDocuments] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [documentUrl, setDocumentUrl] = useState('')

    let {uploaded_by} = useDocument()

    function load_doc_types() {
        ApiService.get(ApiRoutes.DOCUMENTS.DOCUMENT_TYPES)
            .then(res => {
                setTypes(res.data)
            })
            .catch(err => console.error(err))
    }

    function load_patient_docs() {
        setLoading(true)
        ApiService.get(ApiRoutes.DOCUMENTS.PATIENT(patient_id))
            .then(res => {
                setDocuments(res.data)
            })
            .then(() => load_doc_types())
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        load_patient_docs()
    }, [])
    return <>
        <DocumentPreview isOpen={isOpen} onClose={() => setIsOpen(false)} url={documentUrl}/>
        <div className="">
            <div className="flow-root">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle ">
                        <div className="overflow-hidden shadow">
                            {loading ? <Loader/> :
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-100">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6">
                                            Document Type Name
                                        </th>

                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            Documents
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white w-full">
                                    {
                                        types.map((form, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <Disclosure className={'col-span-2'}>
                                                        {({open}) => (
                                                            <>
                                                                <Disclosure.Button
                                                                    className={'p-3 col-span-2 text-green-600 ml-3'}>
                                                                    <div className='grid grid-cols-2'>
                                                                        <p className={open ? 'font-bold' : ''}>{form.name}</p>
                                                                        <ChevronRightIcon
                                                                            className={`h-5 w-auto pl-2  font-bold ${open ? 'rotate-90 transform' : ''} `}/>
                                                                    </div>
                                                                </Disclosure.Button>
                                                                <Disclosure.Panel className={'col-span-2'}>
                                                                    <table
                                                                        className="min-w-full divide-y divide-gray-300 p-3 mx-4 border-2 border-green-600 mr-3 rounded mb-3 mr-3">
                                                                        <thead className="bg-gray-100">
                                                                        <tr>
                                                                            <th scope="col"
                                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6">
                                                                                Document Name
                                                                            </th>
                                                                            <th scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                                                                Uploaded By
                                                                            </th>
                                                                            <th scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                                                                Date Uploaded
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody
                                                                            className="divide-y divide-gray-200 bg-white w-full">
                                                                        {
                                                                            documents.filter(x => x.doc_type?.id === form?.id).map((document, index) => {
                                                                                return <tr key={index}>
                                                                                    <td className="flex items-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-green-600 cursor-pointer"
                                                                                        onClick={() => {
                                                                                            setDocumentUrl(document?.attachment)
                                                                                            setIsOpen(true)
                                                                                        }}>
                                                                                        <DocumentIcon
                                                                                            className='h-4 w-auto mr-1'/> {document.name}
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                                                        {uploaded_by(document.uploaded_by)}
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                                                        {new Date(document.created).toDateString()}
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </Disclosure.Panel>
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                </td>
                                                <td>
                                                    <div className='flex items-center pl-3'>
                                                        <FolderIcon className='h-5 w-auto text-green-600 mr-2'/>
                                                        <p>({documents.filter(x => x.doc_type?.id === form?.id).length})</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <td>
                                            <Disclosure className={'col-span-2'}>
                                                {({open}) => (
                                                    <>
                                                        <Disclosure.Button
                                                            className={'p-3 col-span-2 text-green-600 ml-3'}>
                                                            <div className='grid grid-cols-2'>
                                                                <p className={open ? 'font-bold' : ''}>Uncategorised</p>
                                                                <ChevronRightIcon
                                                                    className={`h-5 w-auto pl-2  font-bold ${open ? 'rotate-90 transform' : ''} `}/>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className={'col-span-2'}>
                                                            <table
                                                                className="min-w-full divide-y divide-gray-300 p-3 mx-4 border-2 border-green-600 mr-3 rounded mb-3">
                                                                <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th scope="col"
                                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6">
                                                                        Document Name
                                                                    </th>
                                                                    <th scope="col"
                                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                                                        Uploaded By
                                                                    </th>
                                                                    <th scope="col"
                                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                                                        Date Uploaded
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody
                                                                    className="divide-y divide-gray-200 bg-white w-full">
                                                                {
                                                                    documents.filter(x => x.doc_type === null).map((document, index) => {
                                                                        return <tr key={index}>
                                                                            <td className="flex items-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-green-600 cursor-pointer"
                                                                                onClick={() => {
                                                                                    setDocumentUrl(document?.attachment)
                                                                                    setIsOpen(true)
                                                                                }}>
                                                                                <DocumentIcon
                                                                                    className='h-4 w-auto mr-1'/> {document.name}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 pl-1 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                                                {uploaded_by(document.uploaded_by)}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                                                {new Date(document.created).toDateString()}
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        </td>
                                        <td>
                                            <div className='flex items-center pl-3'>
                                                <FolderIcon className='h-5 w-auto text-green-600 mr-2'/>
                                                <p>({documents.filter(x => x.doc_type === null).length})</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
