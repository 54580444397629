import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ConvertDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}`
}

function formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
}

const exportPdf = async (element, filename) => {

    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;
    const newWidth = element.scrollWidth - element.clientWidth;
    if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;
        bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF();
    pdf.addImage(image, 'PNG', 0, 0);
    pdf.save(`${filename}.pdf`);
    console.log('Here')
}

function convertFullDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${days[new_date.getDay()]}, ${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}, ${new_date.getUTCHours()}:${new_date.getUTCMinutes() < 10 ? '0' : ''}${new_date.getUTCMinutes()}`
}
export const useUtils = () => {
    return {ConvertDateToString, formatPhoneNumber, exportPdf, convertFullDateToString}
}