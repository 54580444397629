import React from 'react';
import {Dialog, Transition} from '@headlessui/react';

export function DocumentPreview({url, isOpen, onClose}) {

    function isMsDoc() {
        const officeDocumentRegex = /\.(docx|doc|pptx|ppt|xlsx|xls)$/i;
        return officeDocumentRegex.test(url);
    }

    return <>
        <Transition.Root show={isOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50"/>
                    </Transition.Child>

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
          </span>

                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-4xl p-2 my-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                            {
                                isMsDoc() ? <iframe
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                                        className='h-screen w-full rounded'/>
                                    :
                                    <object data={url} type="application/pdf" className='h-screen w-full rounded '/>
                            }
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}
