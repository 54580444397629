import ClinicianBoard from "pages/clinician/dashboard/board";
import Patients from "pages/clinician/Patients";
import {HomeIcon, UserIcon} from "@heroicons/react/24/outline";

export const ClinicianRoutes = [
    {
        path: "/",
        name: "Dashboard",
        icon: HomeIcon,
        layout: "/portal",
        display: true,
        permission: 'can_view_dashboard',
        component: ClinicianBoard
    },
    {
        path: "/patients",
        name: "Patient List",
        icon: UserIcon,
        layout: "/portal",
        display: true,
        permission: 'can_view_all_patient_records',
        component: Patients
    },
];
