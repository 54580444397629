import {usePatient} from "../Hooks/PatientHook";


export function PatientDetailsCard({user}) {
    let {getAgeFromIdNumber, getBirthdateFromIdNumber, getSexFromIdNumber} = usePatient()

    return <>
        <div className="max-w-full mx-auto mt-5 ">
            <div className="mx-auto mt-8 grid grid-cols-1 lg:grid-flow-col-dense border-2 border-gray-200 rounded">
                <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow-lg sm:rounded">
                            <div className="grid grid-cols-8">
                                <div className="px-4 py-5 sm:px-6 bg-gray-100 border col-span-5">
                                    <h2 id="applicant-information-title"
                                        className="text-lg font-medium leading-6 text-gray-900">
                                        {`${user?.first_name} ${user?.last_name}`}
                                    </h2>
                                    {
                                        user?.practice?.length > 0 ?
                                            <p className="text-sm text-gray-500">{user?.practice[0]?.name}</p> : null
                                    }
                                    {/*<p className="text-sm text-gray-500">{user?.practice[0]?.name}</p>*/}
                                </div>
                                <div className="px-4 py-5 sm:px-6 bg-gray-100 border col-span-3">
                                    {
                                        user?.medical_aid === null ?
                                            <p className="text-gray-500"> No medical aid details provided.</p> :

                                            <div className='grid grid-cols-3 gap-0'>
                                                <p className="text-gray-500">{`${user?.medical_aid?.provider?.name ?? ''}`}</p>
                                                <p className="text-gray-500">{`${user?.medical_aid?.plan ?? ''}`}</p>
                                                <p className="text-gray-500">{`${user?.medical_aid?.number ?? ''}`}</p>
                                                <p className="text-gray-500">{`${user?.medical_aid?.principal_member_id ?? ''}`}</p>
                                                <p className="text-gray-500">{`${user?.medical_aid?.principal_name ?? ''}`}</p>
                                                <p className="text-gray-500">{`${user?.medical_aid?.principal_surname ?? ''}`}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-900">ID number</dt>
                                        <dd className="mt-1 text-sm text-gray-700">{user?.id_number}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-900">Email address</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{user?.email}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-900">Phone</dt>
                                        <dd className="mt-1 text-sm text-gray-700">{user?.cellphone}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-900">Gender</dt>
                                        <dd className="mt-1 text-sm text-gray-700">{getSexFromIdNumber(user?.id_number)}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Age</dt>
                                        <dd className="mt-1 text-sm text-gray-700">{getAgeFromIdNumber(user?.id_number)}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-900">Date of Birth</dt>
                                        <dd className="mt-1 text-sm text-gray-700">{getBirthdateFromIdNumber(user?.id_number)}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </>
}
