import React from "react";
import {Container} from "reactstrap";
import Footer from "components/Footers/Footer";

const Terms = () => {
    return <>
        <Container className="mt-6">
            <h1>TERMS & CONDITIONS</h1>
            <p>Disclaimer/Terms and Conditions Governing General Use of the Clavkoo Platform.</p>
            <p>The following terms and conditions, in addition to those laid out in the Platform Privacy Policy apply to
                the Clavkoo Platform. Please read these terms and conditions carefully each time you access this site or
                any of our other Platforms, as the terms may change from time to time.</p>
            <br/>
            <h5 className='font-weight-bold'>1. General Disclaimer.</h5>
            <p>All Material including any links to other sites and content found at linked sites is provided "as is" and
                without any express or implied warranties including warranties of merchantability or fitness for a
                particular purpose. Due to the nature of the Internet, we do not warrant that access to this Platform or
                any of its pages will be uninterrupted or error free. The Platform does not warrant or make any
                representations regarding the usefulness of or the expected results of the material contained on this
                Platform. Note that some jurisdictions may not allow the exclusion of implied warranties so some of the
                above exclusions may not apply to you. Please check your local laws for any such restrictions.</p>
            <h5 className='font-weight-bold'>2. Agreement.</h5>
            <p>https://clavkoo.org ("the Platform", "this Platform") is an Internet-based online information and
                communication service provided by Clavkoo (“the Platform”, "we"). It is sometimes referred to in this
                Agreement as the Service. You agree to be bound by the terms and conditions of this Agreement, which
                includes the Platform Privacy Policy linked to this Agreement. The Platform may modify this Agreement at
                any time. You agree to review this Agreement periodically to ensure that you are aware of any
                modifications. Your continued access or use of the Platform after the modifications have become
                effective shall be deemed as evidence of your acceptance of the modified Agreement.</p>
            <h5 className='font-weight-bold'>3. License.</h5>
            <p>We hereby grant you the right to view and use this Platform subject to the terms and conditions of
                this Agreement.
                Our designated contact for obtaining written consent is:<br/>
                Clavkoo<br/>
                22 1st Avenue, Auckland Park<br/>
                Johannesburg, 2092<br/>
            </p>
            <h5 className='font-weight-bold'>4. Privacy.</h5>
            <p>We take your personal privacy seriously. For information about how we secure personal information,
                please read section 7. Security below. For Information about how we use and secure a registered
                user’s information as well as your rights and responsibilities regarding the use of these features,
                please read our Platform Privacy Policies, which is incorporated by reference into this Agreement.
                In addition to these policies, this Platform fully complies with the Protection of Personal
                Information Act (POPI Act).</p>
            <h5 className='font-weight-bold'>5. Warranty</h5>
            <p><strong>5. 1 Patients</strong></p>
            <p>The health information on this Platform is provided by the patient, clinicians, and non-clinicians
                solely for the purposes of coordinating healthcare. The platform aims to enhance medical care for
                our patients. The Platform assumes no responsibility for any circumstances arising out of the use,
                misuse, interpretation, or application of any information supplied on this Platform.<br/>
                <br/>
                Always consult with your health care professional for appropriate examinations, treatment, testing,
                and care recommendations. Do not rely on information on this Platform as a tool for self-diagnosis.
                If you have a specific medical condition, please contact your health care provider. Use of this
                Platform does not replace medical consultations with a qualified health or medical professional to
                meet the health and medical needs of you or a loved one.<br/>
                <br/>
                In addition, while the Platform frequently updates its contents, medical information changes rapidly
                and therefore, some information may be out of date, and/or contain inaccuracies or typographical
                errors.</p>
            <p><strong>5.2 Clinicians</strong></p>
            <p>The health information on this Platform is provided by the patient, clinicians, and non-clinicians
                solely for the purposes of coordinating healthcare. The platform aims to enhance medical care for
                our patients. The Platform assumes no responsibility for any circumstances arising out of the use,
                misuse, interpretation, or application of any information supplied on this Platform.<br/>
                <br/>
                In addition, while the Platform frequently updates its contents, medical information changes rapidly
                and therefore, some information may be out of date, and/or contain inaccuracies or typographical
                errors.</p>
            <h5 className='font-weight-bold'>6. Limitation of Liability.</h5>
            <p>You agree by accessing this Platform that under no circumstances or any theories of liability under
                international or civil, common or statutory law including but not limited to strict liability,
                negligence or other tort theories or contract, patent or copyright laws, will the Platform be liable
                for damages of any kind occurring from the use of this Platform or any information, goods or services
                obtained on this Platform including direct, indirect, consequential, incidental, or punitive damages
                (even if the platform has been advised of the possibility of such damages), to the fullest extent
                permitted by law. Some jurisdictions do not allow the exclusion or limitation of certain damages so
                some of these limitations may not apply to you.</p>
            <h5 className='font-weight-bold'>7. Security.</h5>
            <p>We will only use the information you submit to provide you with the services you have requested and
                as otherwise described in this Platform Privacy Policy.</p>
            <p><strong>We use secure technology</strong></p>
            <p>In addition to our Platform Privacy Policy, we also take the technical side of security seriously.
                Any personally identifying information you submit is stored on a secure server in a way that maximizes
                security and confidentiality. Our servers are behind a complex series of firewalls to add an extra
                layer of security.</p>
            <p>Access to the information you submit
                Information you submit is limited to those employees and staff who have a need to use the information in
                the following manner:</p>
            <ul>
                <li>
                    Direct response to your inquiry. If you send a non-urgent inquiry through the Contact Us section of
                    this Platform, information is limited to those individuals authorized to review, research and
                    respond to
                    your inquiry have access to that information.
                </li>
                <li>
                    Platform Maintenance. Sometimes our technical staff may view data in the course of their work. They
                    are governed by special rules to assure accesses are for legitimate reasons and we monitor the
                    access to
                    all Web technology.
                </li>
            </ul>

            <h5 className='font-weight-bold'>8. User Representations & Responsibilities.</h5>
            <p>You represent and warrant that you are at least 18 years of age and that you possess the legal right and
                ability to enter into this Agreement and to use this Platform in accordance with this Agreement. You
                agree to be financially responsible for your use of this Platform and to comply with your
                responsibilities and obligations as stated in this Agreement.<br/>
                <br/>
                <strong>User Submissions</strong>
                As a user, you are responsible for your own communications and are responsible for the consequences of
                their posting. You must not, and by using this Platform you agree not to, do the following things: post
                highly confidential information as described in the Notice of Privacy Practices, post material that is
                copyrighted, unless you are the copyright owner or have the permission of the copyright owner to post
                it; post material that reveals trade secrets, unless you own them or have the permission of the owner;
                post material that infringes on any other intellectual property rights of others or on the privacy or
                publicity rights of others; post material that is obscene, defamatory, threatening, harassing, abusive,
                hateful, or embarrassing to another user or any other person or entity; post a sexually-explicit image
                or statement; post advertisements or solicitations of business, post chain letters or pyramid schemes;
                impersonate another person; or post material that contains viruses, Trojan horses, worms, time bombs,
                cancelbots or other computer programming routines or engines that are intended to damage, detrimentally
                interfere with, surreptitiously intercept or expropriate any system, data or information.<br/>
                <br/>
                The Platform has taken reasonable steps and has employed industry-standard practices and technology to
                ensure the integrity and confidentiality of your personally identifiable information; however, since
                even the most secure system can be violated, the Platform cannot guarantee security.<br/>
                <br/>
                Completion and submission of the form provided under Contact Us for use in providing personal
                information for the purpose of obtaining additional information from the facility serves as conclusive
                acceptance of the terms in the General Disclaimer, Platform Privacy Policy, and Notice of Privacy
                Practices.<br/>
                <br/>
                <strong>Minors and Child Online Privacy Protection Act</strong><br/>
                This Platform does direct content to Minors and does intend to collect personal information from Minors
                and therefore is subject to the Child Online Privacy Protection Act.<br/>
                <br/>
                If you allow your minor child, or a child for whom you are legal guardian (a "Minor"), to access and use
                this Platform, you agree that you will be solely responsible for: (i) the online conduct of such Minor;
                (ii) monitoring such Minor's access to and use of this Platform; and (iii) the consequences of any use.
            </p>
        </Container>
        <Footer dark={false}/>
    </>
}
export default Terms;
