import Logo from "assets/img/brand/Icon/SVG/Icon.svg";
import React, {Fragment} from "react";
import Sidebar from "components/Sidebar/Sidebar";
import {Switch} from "react-router-dom";


import PrivateRoute from "components/PrivateComponents";
import ClinicianBoard from "pages/clinician/dashboard/board";
import {ClinicianRoutes} from "routes/ClinicianRoutes";
import Patients from "pages/clinician/Patients";
import Profile from "pages/patient/profile";


export default function Clinician() {

    return <>
        <div>
            <Sidebar
                routes={ClinicianRoutes}
                logo={{
                    innerLink: "/clinician",
                    imgSrc: Logo,
                    imgAlt: "clavkoo",
                }}
            />
            <div className="lg:pl-72">
                <main className="py-10">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="main-content -mt-4">
                            <Switch>
                                <PrivateRoute path="/" exact component={ClinicianBoard}/>
                                <PrivateRoute path="/dashboard" exact component={ClinicianBoard}/>
                                <PrivateRoute path="/patients" component={Patients}/>
                                <PrivateRoute path="/profile/:id" component={Profile}/>
                            </Switch>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>
}
