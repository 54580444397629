import React from "react";
import {Container} from "reactstrap";
import {Link} from "react-router-dom";

const Footer = ({dark = false}) => {

    return (
        <footer className={`col-12 py-6 ${dark ? 'bg-dark' : ''}`}>
            <Container>
                <div>
                    <p className={`copyright text-center ${dark ? 'text-white' : ''}`}>
                        <a
                            className="font-weight-bold ml-1 text-center"
                            href="mailto:info@clavkoo.org"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Clavkoo{" "}
                        </a>
                        {' '}|{' '}
                        Copyright © {new Date().getFullYear()}{" "}
                        {'  '}|{' '}
                        <Link to="/termsandconditions" target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                        </Link>
                        {'  '}|{' '}
                        <Link to="/termsofuse" target="_blank" rel="noopener noreferrer">
                            Terms of Use
                        </Link>
                    </p>

                </div>
            </Container>
        </footer>
    );
}

export default Footer;
