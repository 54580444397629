import React, {useState, useEffect} from "react"
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from "@fortawesome/free-solid-svg-icons"
import Loader from "components/Loader"
import '../../assets/css/profile-card.css'
import {useAuthentication} from "../Hooks/AuthHook"
import {useUtils} from "../Hooks/utils"
import { Fragment } from 'react'
import {ApiRoutes, ApiService} from "@clavkoo/api-service"
import {PhoneIcon} from "@heroicons/react/24/outline"


export default function PatientCard({patient}) {
    const [documents, setDocuments] = useState([])
    const [formTypes, setFormTypes] = useState([])
    let {hasPermission} = useAuthentication();

    let {formatPhoneNumber} = useUtils()

    function load_doc_types() {
        ApiService.get(ApiRoutes.DOCUMENTS.DOCUMENT_TYPES)
            .then(res => {
                setFormTypes(res.data)
            })
            .catch(err => console.error(err))
    }
    function load_documents() {
        ApiService.get(ApiRoutes.DOCUMENTS.PATIENT(patient?.id_number))
            .then(res => {
                setDocuments(res.data)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        load_doc_types()
        load_documents()
    }, [patient])


    function form_exists(form_id) {
        if (!documents.length > 0) {
            return false
        }

        return documents.find(obj => {
            return obj?.doc_type?.id === form_id
        }) !== undefined;
    }

    return <>
        <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="card-container mb-4 bg-green-500 shadow-2xl border-2 border-green-600">
                {hasPermission('can_view_patient_profile') ?
                    <Link to={`/profile/${patient?.id_number}`}>
                        <>
                            <h3 className="text-white">{`${patient?.first_name} ${patient?.last_name}`}</h3>
                            <div className='flex justify-center mx-auto'>
                                <PhoneIcon className='h-4 mx-2 mt-1' />
                                <h5 className="text-white"> {`  ${formatPhoneNumber(patient?.cellphone)}`}</h5>
                            </div>

                        </>
                    </Link>
                    :
                    <>
                        <h3 className="text-white">{`${patient?.first_name} ${patient?.last_name}`}</h3>
                        <h5 className="text-white"><FontAwesomeIcon icon={faPhone}/> {`  ${formatPhoneNumber(patient?.cellphone)}`}</h5>
                    </>
                }
                <div className="skills">
                    <h6 className='text-white text-sm'>Patient Documents</h6>
                    {
                        formTypes.length === 0 ? <Loader/> : null
                    }

                    <ul>
                        {
                            formTypes.map((type, index) => {
                                return <li key={index}
                                           className={`inline-flex items-center rounded border-2  px-3 py-3 text-center text-xs font-medium ${form_exists(type.id) ? 'bg-green-400 text-black border-green-600' : 'bg-red-500 text-white border-red-600'}`}>
                                    <Link to={`/profile/${patient?.id_number}`}
                                          className={`hover:text-white ${form_exists(type.id) ? 'text-gray-800' : 'bg-red-500 text-white'}`}>{type.name}</Link>
                                    {' '} ({documents.filter(x=>x.doc_type?.id === type?.id).length ?? 0})
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>


    </>
}
